(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Phases
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Phases', Phases);

  function Phases($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phases');
  }
}());
